import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../src/layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_08283616 from 'nuxt_plugin_plugin_08283616' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_plugin_42bab598 from 'nuxt_plugin_plugin_42bab598' // Source: ./vuetify/plugin.js (mode: 'all')
import nuxt_plugin_plugin_47557304 from 'nuxt_plugin_plugin_47557304' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_nuxtsocketio_5fa346b6 from 'nuxt_plugin_nuxtsocketio_5fa346b6' // Source: ./nuxt-socket-io.js (mode: 'all')
import nuxt_plugin_workbox_568eec48 from 'nuxt_plugin_workbox_568eec48' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_737b6b5b from 'nuxt_plugin_metaplugin_737b6b5b' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_782310cf from 'nuxt_plugin_iconplugin_782310cf' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_d55bcc58 from 'nuxt_plugin_cookieuniversalnuxt_d55bcc58' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_toast_0b8ac24a from 'nuxt_plugin_toast_0b8ac24a' // Source: ./toast.js (mode: 'client')
import nuxt_plugin_pluginutils_1e7d398f from 'nuxt_plugin_pluginutils_1e7d398f' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_2a159f1a from 'nuxt_plugin_pluginrouting_2a159f1a' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_21e99ad6 from 'nuxt_plugin_pluginmain_21e99ad6' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_dayjsplugin_3974059e from 'nuxt_plugin_dayjsplugin_3974059e' // Source: ./dayjs-plugin.js (mode: 'all')
import nuxt_plugin_nuxtuseragent_3915f5f5 from 'nuxt_plugin_nuxtuseragent_3915f5f5' // Source: ./nuxt-user-agent.js (mode: 'all')
import nuxt_plugin_axios_2e0a522c from 'nuxt_plugin_axios_2e0a522c' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_gtm_2a91b402 from 'nuxt_plugin_gtm_2a91b402' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_lightgalleryclient_9216a8d4 from 'nuxt_plugin_lightgalleryclient_9216a8d4' // Source: ./nuxt-module-media/plugins/lightgallery.client.js (mode: 'client')
import nuxt_plugin_modulestore_25f5ea0a from 'nuxt_plugin_modulestore_25f5ea0a' // Source: ./nuxt-module-data-layer/store/module-store.js (mode: 'all')
import nuxt_plugin_index_fe63b61c from 'nuxt_plugin_index_fe63b61c' // Source: ./nuxt-module-data-layer/store/index.js (mode: 'all')
import nuxt_plugin_authenstore_435ec1c5 from 'nuxt_plugin_authenstore_435ec1c5' // Source: ./nuxt-module-data-layer/store/authen-store.js (mode: 'all')
import nuxt_plugin_track_5d70506a from 'nuxt_plugin_track_5d70506a' // Source: ./nuxt-module-data-layer/middleware/track.js (mode: 'all')
import nuxt_plugin_savequerytokens_23bd1cb4 from 'nuxt_plugin_savequerytokens_23bd1cb4' // Source: ./nuxt-module-data-layer/middleware/save-query-tokens.js (mode: 'all')
import nuxt_plugin_authenredirect_5d33473d from 'nuxt_plugin_authenredirect_5d33473d' // Source: ./nuxt-module-data-layer/middleware/authen-redirect.js (mode: 'all')
import nuxt_plugin_refreshtokenclient_e54f9b12 from 'nuxt_plugin_refreshtokenclient_e54f9b12' // Source: ./nuxt-module-data-layer/plugins/refresh-token.client.js (mode: 'client')
import nuxt_plugin_axiosclient_2af67cb4 from 'nuxt_plugin_axiosclient_2af67cb4' // Source: ./nuxt-module-data-layer/plugins/axios.client.js (mode: 'client')
import nuxt_plugin_axioslogclient_2e1d6cdd from 'nuxt_plugin_axioslogclient_2e1d6cdd' // Source: ./nuxt-module-data-layer/plugins/axios-log.client.js (mode: 'client')
import nuxt_plugin_apmclient_695c6c64 from 'nuxt_plugin_apmclient_695c6c64' // Source: ./nuxt-module-data-layer/plugins/apm.client.js (mode: 'client')
import nuxt_plugin_useTimeSelect_bbeb51ee from 'nuxt_plugin_useTimeSelect_bbeb51ee' // Source: ./vue-component/compositions/useTimeSelect.js (mode: 'all')
import nuxt_plugin_useDateRange_06735a2d from 'nuxt_plugin_useDateRange_06735a2d' // Source: ./vue-component/compositions/useDateRange.js (mode: 'all')
import nuxt_plugin_usevideoresize_53711449 from 'nuxt_plugin_usevideoresize_53711449' // Source: ./vue-component/compositions/use-video-resize.js (mode: 'all')
import nuxt_plugin_usescript_701cc4b8 from 'nuxt_plugin_usescript_701cc4b8' // Source: ./vue-component/compositions/use-script.js (mode: 'all')
import nuxt_plugin_usepreferences_208362a7 from 'nuxt_plugin_usepreferences_208362a7' // Source: ./vue-component/compositions/use-preferences.js (mode: 'all')
import nuxt_plugin_usepermissions_cb525cca from 'nuxt_plugin_usepermissions_cb525cca' // Source: ./vue-component/compositions/use-permissions.js (mode: 'all')
import nuxt_plugin_uselog_5681d2db from 'nuxt_plugin_uselog_5681d2db' // Source: ./vue-component/compositions/use-log.js (mode: 'all')
import nuxt_plugin_usejwt_538ec678 from 'nuxt_plugin_usejwt_538ec678' // Source: ./vue-component/compositions/use-jwt.js (mode: 'all')
import nuxt_plugin_usedebouncedref_f2c66804 from 'nuxt_plugin_usedebouncedref_f2c66804' // Source: ./vue-component/compositions/use-debounced-ref.js (mode: 'all')
import nuxt_plugin_usecookie_72f35d2a from 'nuxt_plugin_usecookie_72f35d2a' // Source: ./vue-component/compositions/use-cookie.js (mode: 'all')
import nuxt_plugin_textonbackground_a0e1434e from 'nuxt_plugin_textonbackground_a0e1434e' // Source: ./vue-component/compositions/text-on-background.js (mode: 'all')
import nuxt_plugin_rerender_0c008eae from 'nuxt_plugin_rerender_0c008eae' // Source: ./vue-component/compositions/re-render.js (mode: 'all')
import nuxt_plugin_index_715ce7f2 from 'nuxt_plugin_index_715ce7f2' // Source: ./vue-component/compositions/index.js (mode: 'all')
import nuxt_plugin_hextorgb_e7b4a2f0 from 'nuxt_plugin_hextorgb_e7b4a2f0' // Source: ./vue-component/compositions/hex-to-rgb.js (mode: 'all')
import nuxt_plugin_haptics_c2871946 from 'nuxt_plugin_haptics_c2871946' // Source: ./vue-component/compositions/haptics.js (mode: 'all')
import nuxt_plugin_locale_208ff678 from 'nuxt_plugin_locale_208ff678' // Source: ./vue-component/middleware/locale.js (mode: 'all')
import nuxt_plugin_nuxttypedvuex_6c1a7c03 from 'nuxt_plugin_nuxttypedvuex_6c1a7c03' // Source: ./nuxt-typed-vuex.js (mode: 'all')
import nuxt_plugin_meta_598eade0 from 'nuxt_plugin_meta_598eade0' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":true,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0, minimal-ui"}],"link":[{"rel":"preconnect","href":"https:\u002F\u002Fwww.google-analytics.com"},{"rel":"preconnect","href":"https:\u002F\u002Fwww.googleadservices.com"}],"style":[],"script":[{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function(w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e][p]&&w[e][p]()))?1:0})(window,navigator,'doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[x]={};w._gtm_inject=function(i){if(w.doNotTrack||w[x][i])return;w[x][i]=1;w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i;f.parentNode.insertBefore(j,f);};w[y]('GTM-NL72NKH')})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"noscript":[{"hid":"gtm-noscript","pbody":true,"innerHTML":"\u003Ciframe src=\"https:\u002F\u002Fwww.googletagmanager.com\u002Fns.html?id=GTM-NL72NKH&\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\" title=\"gtm\"\u003E\u003C\u002Fiframe\u003E"}],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_08283616 === 'function') {
    await nuxt_plugin_plugin_08283616(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_42bab598 === 'function') {
    await nuxt_plugin_plugin_42bab598(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_47557304 === 'function') {
    await nuxt_plugin_plugin_47557304(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtsocketio_5fa346b6 === 'function') {
    await nuxt_plugin_nuxtsocketio_5fa346b6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_568eec48 === 'function') {
    await nuxt_plugin_workbox_568eec48(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_737b6b5b === 'function') {
    await nuxt_plugin_metaplugin_737b6b5b(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_782310cf === 'function') {
    await nuxt_plugin_iconplugin_782310cf(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_d55bcc58 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_d55bcc58(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_toast_0b8ac24a === 'function') {
    await nuxt_plugin_toast_0b8ac24a(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_1e7d398f === 'function') {
    await nuxt_plugin_pluginutils_1e7d398f(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_2a159f1a === 'function') {
    await nuxt_plugin_pluginrouting_2a159f1a(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_21e99ad6 === 'function') {
    await nuxt_plugin_pluginmain_21e99ad6(app.context, inject)
  }

  if (typeof nuxt_plugin_dayjsplugin_3974059e === 'function') {
    await nuxt_plugin_dayjsplugin_3974059e(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtuseragent_3915f5f5 === 'function') {
    await nuxt_plugin_nuxtuseragent_3915f5f5(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_2e0a522c === 'function') {
    await nuxt_plugin_axios_2e0a522c(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_2a91b402 === 'function') {
    await nuxt_plugin_gtm_2a91b402(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_lightgalleryclient_9216a8d4 === 'function') {
    await nuxt_plugin_lightgalleryclient_9216a8d4(app.context, inject)
  }

  if (typeof nuxt_plugin_modulestore_25f5ea0a === 'function') {
    await nuxt_plugin_modulestore_25f5ea0a(app.context, inject)
  }

  if (typeof nuxt_plugin_index_fe63b61c === 'function') {
    await nuxt_plugin_index_fe63b61c(app.context, inject)
  }

  if (typeof nuxt_plugin_authenstore_435ec1c5 === 'function') {
    await nuxt_plugin_authenstore_435ec1c5(app.context, inject)
  }

  if (typeof nuxt_plugin_track_5d70506a === 'function') {
    await nuxt_plugin_track_5d70506a(app.context, inject)
  }

  if (typeof nuxt_plugin_savequerytokens_23bd1cb4 === 'function') {
    await nuxt_plugin_savequerytokens_23bd1cb4(app.context, inject)
  }

  if (typeof nuxt_plugin_authenredirect_5d33473d === 'function') {
    await nuxt_plugin_authenredirect_5d33473d(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_refreshtokenclient_e54f9b12 === 'function') {
    await nuxt_plugin_refreshtokenclient_e54f9b12(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_axiosclient_2af67cb4 === 'function') {
    await nuxt_plugin_axiosclient_2af67cb4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_axioslogclient_2e1d6cdd === 'function') {
    await nuxt_plugin_axioslogclient_2e1d6cdd(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_apmclient_695c6c64 === 'function') {
    await nuxt_plugin_apmclient_695c6c64(app.context, inject)
  }

  if (typeof nuxt_plugin_useTimeSelect_bbeb51ee === 'function') {
    await nuxt_plugin_useTimeSelect_bbeb51ee(app.context, inject)
  }

  if (typeof nuxt_plugin_useDateRange_06735a2d === 'function') {
    await nuxt_plugin_useDateRange_06735a2d(app.context, inject)
  }

  if (typeof nuxt_plugin_usevideoresize_53711449 === 'function') {
    await nuxt_plugin_usevideoresize_53711449(app.context, inject)
  }

  if (typeof nuxt_plugin_usescript_701cc4b8 === 'function') {
    await nuxt_plugin_usescript_701cc4b8(app.context, inject)
  }

  if (typeof nuxt_plugin_usepreferences_208362a7 === 'function') {
    await nuxt_plugin_usepreferences_208362a7(app.context, inject)
  }

  if (typeof nuxt_plugin_usepermissions_cb525cca === 'function') {
    await nuxt_plugin_usepermissions_cb525cca(app.context, inject)
  }

  if (typeof nuxt_plugin_uselog_5681d2db === 'function') {
    await nuxt_plugin_uselog_5681d2db(app.context, inject)
  }

  if (typeof nuxt_plugin_usejwt_538ec678 === 'function') {
    await nuxt_plugin_usejwt_538ec678(app.context, inject)
  }

  if (typeof nuxt_plugin_usedebouncedref_f2c66804 === 'function') {
    await nuxt_plugin_usedebouncedref_f2c66804(app.context, inject)
  }

  if (typeof nuxt_plugin_usecookie_72f35d2a === 'function') {
    await nuxt_plugin_usecookie_72f35d2a(app.context, inject)
  }

  if (typeof nuxt_plugin_textonbackground_a0e1434e === 'function') {
    await nuxt_plugin_textonbackground_a0e1434e(app.context, inject)
  }

  if (typeof nuxt_plugin_rerender_0c008eae === 'function') {
    await nuxt_plugin_rerender_0c008eae(app.context, inject)
  }

  if (typeof nuxt_plugin_index_715ce7f2 === 'function') {
    await nuxt_plugin_index_715ce7f2(app.context, inject)
  }

  if (typeof nuxt_plugin_hextorgb_e7b4a2f0 === 'function') {
    await nuxt_plugin_hextorgb_e7b4a2f0(app.context, inject)
  }

  if (typeof nuxt_plugin_haptics_c2871946 === 'function') {
    await nuxt_plugin_haptics_c2871946(app.context, inject)
  }

  if (typeof nuxt_plugin_locale_208ff678 === 'function') {
    await nuxt_plugin_locale_208ff678(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxttypedvuex_6c1a7c03 === 'function') {
    await nuxt_plugin_nuxttypedvuex_6c1a7c03(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_598eade0 === 'function') {
    await nuxt_plugin_meta_598eade0(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
